import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import '@/scss/main.scss'; /* Import main.scss file on bootup */
//import VueGtag from 'vue-gtag'
import { VueReCaptcha } from 'vue-recaptcha-v3'

createApp(App)
    .use(router)
    .use(VueReCaptcha, {
        siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
        loaderOptions: {
            autoHideBadge: false,
        },
    })
    .mount('#app')
