<template>
  <div class="home">
    <img :src="imageUrl" :key="imageVersion" alt="Vue logo" class="rounded-img img-fluid responsive">
    <HelloWorld msg="Welcome to ACDSIS"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  data() {
    return {
      imageUrl: window.location.origin + '/acdsis.jpeg',
      imageVersion: 1,
      name: 'HomeView',
    }
  },
  components: {
    HelloWorld
  }
}
</script>
