<template>
  <div class="contact-page">
    <h1>Coordonnées</h1>
    <p>N'hésitez pas à nous contacter si vous avez des questions ou des préoccupations. Nous sommes toujours là pour vous aider.</p>
    <ul>
      <li>Téléphone: +33601089464</li>
      <li>Courriel: s.djerrah@gmail.com</li>
      <li>Adresse: 35 F rue de l'essonne 91000 evry courcouronne</li>
    </ul>
    <h2>Formulaire de contact</h2>
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <label for="name">Nom</label>
        <input type="text" id="name" v-model="name" required>
      </div>
      <div class="form-group">
        <label for="email">Courriel</label>
        <input type="email" id="email" v-model="email" required>
      </div>
      <div class="form-group">
        <label for="message">Message</label>
        <textarea id="message" v-model="message" rows="5" required></textarea>
      </div>
      <VueReCaptcha isLoaded:true />
      <button type="submit">Envoyer</button>
    </form>
  </div>
</template>

<script>
import '@/scss/contactPage.scss'
import { VueReCaptcha } from 'vue-recaptcha-v3'

export default {
  components: {
    VueReCaptcha,
  },
  data() {
    return {
      name: '',
      email: '',
      message: '',
      token: null,
    }
  },
  methods: {
    async submitForm() {
      const apiUrl = process.env.VUE_APP_BACK_API_URL;

      this.token = await this.$recaptcha('login')
      const response = await fetch(apiUrl + '/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: this.name,
          email: this.email,
          message: this.message,
          token: this.token
        })
      });

      if (response.ok) {
        // Le formulaire a été envoyé avec succès
        // Ajoutez ici la logique pour envoyer le formulaire de contact
        console.log(`Nom: ${this.name}, Email: ${this.email}, Message: ${this.message}, Token: ${this.token}`);
        // Réinitialisez les champs du formulaire après envoi
        this.name = '';
        this.email = '';
        this.message = '';
        this.token = '';
      } else {
        // Une erreur s'est produite lors de l'envoi du formulaire
      }
    }
  },
}
</script>
